// Material-UI styles
import {withStyles, withWidth} from '@material-ui/core';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {getFormSyncErrors, getFormValues, reduxForm, SubmissionError} from 'redux-form';
import ErrorBoundary from '../../components/ErrorBoundary';
import Loading from '../../components/Loading';
import bookingSourceColors from '../../containers/GuestPortal/colors';
import bookingSourceImages from '../../containers/GuestPortal/images';
// layout
import Layout from '../../layouts/GuestPortal';
import {expressRequestIP} from '../../libs/expressRequestIp';
import logRollbarError from '../../libs/rollbar';
import {sendActivityLog, sendSegment as sendSegmentEvent} from '../../libs/segment';
import {formatAddressForAutofill, getOrNull} from '../../libs/utils';
import type {Reservation} from '../../models';
import {LocationObject} from '../../models/reservation';
import {getReservation, setOverlayForm} from '../../reducers/guestportal';
import {guestPortalApi} from '../../reducers/guestPortalApi';
import type {ReduxState} from '../../store/redux.types';
// Autohost components
import * as helpScreens from './components/help';
import {getScreen, screens as formScreens} from './Screens';
import {RiskColor} from './Screens/types';
import styles from './styles';
import type {
    FormKey,
    GuestPortalWizardProps,
    HandleCloseModalState,
    InitialPropsContext,
    SendSegmentOptions,
    StylesType,
} from './types/index';
import {getContextProps} from './utils';
import {isEmpty, otaEmailWarning as isOTAEmail, SyncValidation as validate} from './validation';

const Search = dynamic(() => import('../Search'));
const ErrorBox = dynamic(() => import('../../components/ErrorBox/ErrorBox'));
const NotFound = dynamic(() => import('./components/NotFound'));

const fileInPath = (str: string) => {
    const ignored = ['.png', '.jpg', '.jpeg', '.txt', '.css', '.ico', '.php', '.html', '.cfm', '.cgi', '.xml'];
    return ignored.some((ext) => `${(str && str.toLowerCase()) || ''}`.endsWith(ext));
};

class GuestPortalWizard extends Component<GuestPortalWizardProps> {
    formInitialized = false;
    state = {
        page: 1,
        showCompletedMessage: false,
        submitFormApiError: null,
    };
    unsubscribe_list: (() => void)[] = [];

    static async getInitialProps({store, query, req, res}: InitialPropsContext) {
        const ip = expressRequestIP(req);

        const reservationId = query.id || 'none';
        const domain = (req && req.hostname) || (typeof location !== 'undefined' && location.hostname);
        const exclude = query.exclude;
        //@ts-ignore
        let reservation: Reservation = {};
        if (reservationId !== 'none' && !fileInPath(reservationId as string)) {
            try {
                // @ts-ignore guestportal reducers not TS yet
                reservation = await store.dispatch(getReservation(reservationId, {domain, exclude, ip}));
            } catch (e) {
                console.error("Failed to get reservation", reservationId, e);
            }
        }

        const opertoRedirect = getOrNull('settings.operto_portal_redirect', reservation) || false;
        if (reservation.operto_portal_url && reservation.is_safe && opertoRedirect) {
            if (res) {
                res.writeHead(302, {Location: reservation.operto_portal_url});
                res.end();
                return;
            } else {
                if (typeof window !== 'undefined') {
                    // @ts-ignore
                    window.location = reservation.operto_portal_url;
                    // could be
                    // window.location.href = reservation.operto_portal_url;
                }
            }
        }
        return {reservationId, reservation};
    }

    componentWillReceiveProps = async (nextProps: GuestPortalWizardProps) => {
        // init form and register window event when new reservation info arrives
        if (nextProps.reservation && nextProps.reservation.id && !this.props.reservation) {
            this.initializeForm(nextProps);
            this.registerWindowEvent();
        }

        // send event to Segment when reservation risk color changes
        if (
            this.props.reservation &&
            nextProps.reservation &&
            this.props.reservation.protect_color !== nextProps.reservation.protect_color
        ) {
            // Log the color change
            this.sendSegment('track', {
                name: 'Reservation Risk Color Changed',
                data: {
                    color: nextProps.reservation.protect_color,
                    previous_color: this.props.reservation.protect_color,
                },
            });

            // Recalculate the page based on the new color and screen stack
            if (!nextProps.reservation.completed && nextProps.reservation.last_step) {
                const screen = this.getScreenObject(nextProps);
                const screenIndex = screen.screenStack.findIndex(
                    (s: string) => s === nextProps.reservation?.last_step
                );
                if (Number.isInteger(screenIndex) && screenIndex >= 0 && screenIndex < screen.screenStack.length) {
                    // We want to show the page *after* the last step completed
                    this.setState({ page: screenIndex + 2 });
                }
            }
        }
    };

    componentDidMount = () => {
        const {router, getReservation, reservationId, reservation, change} = this.props;
        const isKiosk = Boolean(router.query.kiosk);
        const isEmbedded = Boolean(router.query.embed);

        // hide feedback tool
        if (isEmbedded) {
            setTimeout(() => {
                const Userback = typeof window !== 'undefined' && window.Userback;
                if (Userback && Userback.hide) {
                    Userback.hide();
                }
            }, 1000);
        }

        if (reservationId !== 'none' && !fileInPath(reservationId)) {
            if (!reservation) {
                getReservation(reservationId);
            } else {
                this.initializeForm(this.props);
                this.registerWindowEvent();
            }
        }
        // ask for browser location permission if not in kiosk mode
        if (typeof navigator !== 'undefined' && navigator.geolocation && !Boolean(router.query.kiosk)) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    change?.('location', {lat: latitude, lon: longitude});
                },
                (error) => {
                    console.warn(`Geolocation API access denied: ${error}`);
                },
            );
        }

        // collect fraud telemetry
        if (reservation && !isKiosk) {
            (async () => {
                // dynamic import
                const fraudTelemetry = (await import('../../libs/fraud')).default;
                // collect telemetry
                await fraudTelemetry(reservation);
            })();
        }
    };

    componentWillUnmount() {
        // Unsubscribe the component from the cached data when unmounting
        this.unsubscribe_list.forEach((unsubscribe) => unsubscribe?.());
    }

    initializeForm = (props: GuestPortalWizardProps) => {
        if (this.formInitialized) {
            return;
        }

        const settings = props.reservation?.settings || {};
        const screensSettings = settings.guest_portal_screens_settings || {};
        const personalInfoSettings = screensSettings.PersonalInfo || {};

        // autofill personal info in form
        if (personalInfoSettings.airbnb_email_optional) this.props.autofill?.('email', props.reservation?.guest_email);
        const emailInvalid = props.reservation?.settings?.guest_portal_force_personal_email ? isOTAEmail : isEmpty;
        !emailInvalid(props.reservation?.guest_email || '') &&
            this.props.autofill?.('email', props.reservation?.guest_email);
        !isEmpty(props.reservation?.guest_full_name || undefined) &&
            this.props.autofill?.('full_name', props.reservation?.guest_full_name);
        !isEmpty(props.reservation?.guest_phone || undefined) &&
            this.props.autofill?.('phone', props.reservation?.guest_phone);
        if (!isEmpty(props.reservation?.location || undefined)) {
            if (typeof props.reservation?.location === 'string') {
                this.props.autofill?.('address', props.reservation?.location);
            }
            if (typeof props.reservation?.location === 'object') {
                if (props.reservation?.location?.address) {
                    this.props.autofill?.('address', props.reservation?.location?.address);
                } else {
                    this.props.autofill?.(
                        'address',
                        formatAddressForAutofill(props.reservation?.location as LocationObject),
                    );
                }
            }
        }

        const screen = this.getScreenObject(props);
        // load previous progress if guest didn't complete last session
        if (props.reservation?.last_step && props.reservation.form) {
            Object.keys(props.reservation.form).forEach((key) => {
                if (!isEmpty(props.reservation?.form?.[key as FormKey])) {
                    this.props.autofill?.(key, props.reservation?.form?.[key as FormKey]);
                }
            });
            // set page to the last page they were on
            if (!props.reservation.completed) {
                const screenIndex = screen.screenStack.findIndex((s: string) => s === props.reservation?.last_step);
                if (Number.isInteger(screenIndex) && screenIndex >= 1 && screenIndex < screen.screenStack.length) {
                    // set Page as index + 2  because
                    // array index starts at 0
                    // and we want to show the page *after* the last step completed
                    this.setState({page: screenIndex + 2});
                }
            }
        }

        // set to `true` to avoid auto-filling the form again
        this.formInitialized = true;

        // show modal that warns user they already completed their check-in
        if (props.reservation?.completed) {
            this.setState({...this.state, page: screen.screenCount});
        }
    };

    registerWindowEvent = () => {
        // warn if user navigates away
        window.onbeforeunload = (event?: BeforeUnloadEvent) => {
            const message = 'Are you sure you want to leave this page? Progress will not be saved.';
            if (typeof event == 'undefined') {
                event = window.event;
            }
            if (event) {
                event.returnValue = message;
            }
            return message;
        };
    };

    // We pass props as a function param, rather than accessing it through `this`
    // Because this is called from initializeForm when itself is called from componentWillReceiveProps
    // so the props on `this` are outdated for what we need to do
    getScreenObject = (props: GuestPortalWizardProps) => {
        const {reservation, width, country, router} = props;
        const {page} = this.state;
        const {isMobile} = getContextProps(router.query, width);
        const settings = (reservation && reservation.settings) || {};
        const params = {country, reservation};
        const riskColor = (reservation && reservation.protect_color) || 'red';
        return getScreen(riskColor as RiskColor, isMobile || false, page - 1, settings, params);
    };

    nextPage = () => {
        const {getReservation, saveProgress, pingStart, reservation, formValues} = this.props;
        const {page} = this.state;

        // save progress
        if (page > 1) {
            const screen = this.getScreenObject(this.props);
            const isEmbedded = Boolean(this.props.router.query.embed);
            // notify from iframe to parent window that guest completed a step
            if (isEmbedded) {
                const message = {
                    type: 'guestportal-step-completed',
                    id: reservation?.id,
                    code: reservation?.confirmation_code,
                    step: screen.screenName,
                };
                window.top.postMessage(JSON.stringify(message), '*');
            }

            const {unsubscribe} = saveProgress({
                reservationId: reservation?.id,
                lastStep: screen.screenName,
                data: formValues,
            });
            this.unsubscribe_list.push(unsubscribe);
        }
        // move to next page
        this.setState(
            {
                page: page + 1,
            },
            () => {
                // ping our API to start the fraud detection process only on second page
                if (page === 2) {
                    const {unsubscribe} = pingStart(reservation?.id);
                    this.unsubscribe_list.push(unsubscribe);
                }

                // reload reservation details to check if Autohost Protect data changed while guest uses the portal
                getReservation(reservation?.id);
            },
        );
    };

    prevPage = () => {
        this.setState({
            page: this.state.page - 1,
        });
    };

    postToParent = () => {
        const {router, reservation} = this.props;
        const isEmbedded = Boolean(router.query.embed);
        // notify from iframe to parent window that guest completed check-in
        if (isEmbedded) {
            const message = {
                type: 'guestportal-completed',
                id: reservation?.id,
                code: reservation?.confirmation_code,
                is_repeat_guest: reservation?.is_repeat_guest,
                is_previously_declined_guest: reservation?.is_previously_declined_guest,
            };
            window.top.postMessage(JSON.stringify(message), '*');
        }
    };

    handleInfo = (screenName: string) => () => {
        this.props.setOverlayForm('Help');
        this.sendSegment('track', {
            name: 'Guest Clicked Info Button',
            data: {screenName},
        });
    };

    handleFinalSubmit = async (form: {}) => {
        const {reservation, submitForm} = this.props;

        // submit form
        let res;
        try {
            res = await submitForm({reservationId: reservation?.id, data: form}).unwrap();
        } catch (e) {
            this.setState({...this.state, submitFormApiError: e});
            return new SubmissionError({_error: e});
        }

        // send event to Segment
        this.sendSegment('track', {
            name: 'Completed Guest Portal Check-in',
            data: {},
        });

        // navigate user to final page
        this.nextPage();

        // notify parent window that guest completed check-in
        this.postToParent();

        return res;
    };

    handleCloseOverlay = () => {
        this.props.setOverlayForm(null);
    };

    handleCloseModal =
        (screenCount: number) =>
        (exit = true) => {
            const state: HandleCloseModalState = {
                showCompletedMessage: false,
            };
            if (exit) {
                state.page = screenCount;
            }
            this.setState(state);
        };

    sendSegment = (event: string, options: SendSegmentOptions) => {
        const {reservation, formValues} = this.props;
        const track = {
            reservation: reservation?.id,
            listing: reservation?.listing_name,
            color: reservation?.protect_color,
            host: reservation?.company ? reservation.company.name : reservation?.host_name,
            name: formValues?.full_name,
            email: formValues?.email,
            ...options.data,
        };
        if (event === 'identify') {
            sendSegmentEvent(event, options);
        }
        if (event === 'track') {
            sendSegmentEvent(event, {name: options.name, data: {...track, ...options.data}});
        }
    };

    addActivityLog = (status: string, action: string, message: string) => {
        const {reservation} = this.props;
        const eventData = {
            uid: reservation?.hid || undefined,
            rid: reservation?.id || undefined,
            status,
            action,
            message,
        };
        sendActivityLog(eventData);
    };

    logError = (error: any, level = 'info') => {
        const {reservation, formValues} = this.props;
        logRollbarError(error, level, {reservation, formValues});
    };

    render() {
        const {classes, error, apiError, reservation, reservationId, loading, width, formErrors, overlay, router} =
            this.props;
        const {page} = this.state;
        const ctxProps = getContextProps(router.query, width);
        const {isEmbedded, isMobile} = ctxProps;
        const validColors = ['green', 'yellow', 'orange', 'red', 'black'];
        const validColor = (color: string) => color && validColors.includes(color.toLowerCase());

        // show loading screen
        if (loading && !reservation) {
            return <Loading />;
        }

        // show error page
        if (reservation && (reservation.error && reservation.message)) {
            return <NotFound error={reservation.message} header={reservation.error} />;
        }

        // show search page (will also render NotFound if missing expected data)
        if (reservationId === 'none' || !reservation || !validColor(reservation.protect_color || '')) {
            return <Search {...this.props} />;
        }

        // email validation
        const invalidEmail = formErrors && !!formErrors['email'];

        // load correct form page
        const {isFinalPage, screenCount, ...screenProps} = this.getScreenObject(this.props);
        // @ts-ignore
        const CurrentScreen = overlay ? formScreens(isMobile).components[overlay] : screenProps.CurrentScreen;
        const percentCompleted = Math.max((page / screenCount) * 100);
        console.log(`Current screen: ${screenProps.screenName}`);

        // update form style classname
        classes.form = classNames(classes.form, {[classes.embedded]: isEmbedded});

        // default props for screens
        const defaultProps = {
            classes,
            reservation,
            width,
            router,
            sendSegment: this.sendSegment,
            addActivityLog: this.addActivityLog,
            logError: this.logError,
            screenStack: screenProps.screenStack,
            screenName: screenProps.screenName,
            color: bookingSourceColors(reservation),
            reloadData: () => this.props.getReservation(reservation.id),
            ...ctxProps,
        };

        // determine if current screen should use a scroll
        let useScroll;
        if (
            [
                'CustomScreen',
                'BackgroundCheck',
                'GuestList',
                'Coronavirus',
                'CreditCheck',
                'BuildingScreen',
                'AuthorityReporting',
                'PersonalInfo',
                'PersonalInfoEdit',
                'SecurityDeposit',
            ].includes(screenProps.screenName)
        ) {
            useScroll = true;
        }
        return (
            <Layout
                reservation={reservation}
                overlay={overlay}
                onBack={overlay ? this.handleCloseOverlay : screenCount !== page && !invalidEmail && this.prevPage}
                bookingSourceImage={bookingSourceImages(reservation, true) || undefined}
                color={bookingSourceColors(reservation)}
                paintBackground={screenCount === page || screenProps.screenName === 'Finish'}
                hideTopbar={page === 1}
                onInfo={
                    Boolean(helpScreens[screenProps.screenName as keyof typeof helpScreens] && !overlay) &&
                    this.handleInfo(screenProps.screenName)
                }
                // onInfo={false}
                progress={percentCompleted}
                useScroll={useScroll}
                isMobile={ctxProps.isMobile || false}
                {...ctxProps}
            >
                {(error || apiError || this.state.submitFormApiError) && (
                    <div className={classes.root}>
                        <div className={classes.form}>
                            <ErrorBox error={error || apiError || this.state.submitFormApiError} />
                        </div>
                    </div>
                )}

                <ErrorBoundary extra={{screenName: screenProps.screenName}}>
                    <CurrentScreen
                        {...defaultProps}
                        onSubmit={
                            overlay ? this.handleCloseOverlay : isFinalPage ? this.handleFinalSubmit : this.nextPage
                        }
                    />
                </ErrorBoundary>
            </Layout>
        );
    }
}

const selector = getFormSyncErrors('guestportal');
const mapStateToProps = (state: ReduxState) => {
    return {
        loading: state.guestportal.loading,
        loaded: state.guestportal.loaded,
        apiError: state.guestportal.error,
        reservation: state.guestportal.reservation,
        overlay: state.guestportal.overlay,
        country: guestPortalApi.endpoints.pingStart.select(state.guestportal.reservation?.id)(state).data?.ip_country,
        formErrors: selector(state),
        formValues: getFormValues('guestportal')(state),
    };
};

const actionProps = {
    getReservation,
    submitForm: guestPortalApi.endpoints.submitForm.initiate,
    setOverlayForm,
    pingStart: guestPortalApi.endpoints.pingStart.initiate,
    saveProgress: guestPortalApi.endpoints.saveProgress.initiate,
};

const ReduxForm = {
    form: 'guestportal', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount,
    validate,
};

export {GuestPortalWizard};
export default compose<GuestPortalWizardProps, {}>(
    withStyles(styles as StylesType),
    withWidth(),
    reduxForm(ReduxForm),
    connect(mapStateToProps, actionProps),
)(GuestPortalWizard);
